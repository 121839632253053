import {
  all,
  filter,
  find,
  groupBy,
  isEmpty,
  keys,
  map,
  prop,
  propEq,
  sortBy,
  values,
} from 'ramda';
import createCachedSelector from 're-reselect';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { IChecklistFilter, IChecklistGroup } from 'lib/checklist/types';
import { getGroupsTranslation } from 'lib/countries/data/groups';
import { getTasksTranslation } from 'lib/countries/data/tasks';
import { IApplicationState } from 'lib/types';
import { createDeepEqualSelector } from 'lib/utils/selectors';
import { sortPeriods } from '../Tasks.utils';

/* ############################################################################
 *  INTERNAL input-selectors without mutations
 * ######################################################################### */

export const getChecklistInitializedAt = (state: IApplicationState) =>
  state.weddings.profile.tasks.initializedAt;
const seedGroups = (state: IApplicationState) => state.app.groups;
const userLocale = (state: IApplicationState) => state.users.user?.l10n?.locale;
const groups = (state: IApplicationState) => state.checklist.groups;
const periods = (state: IApplicationState) => state.checklist.periods;
const tasks = (state: IApplicationState) => state.checklist.tasks;
const tasksInitial = (state: IApplicationState) => state.checklist.tasksInitial;
const detailsTaskId = (state: IApplicationState) => state.checklist.detailsTaskId;

/* ############################################################################
 *  MEMOIZED also with multiple cache and deep equal memoization
 * ######################################################################### */

/**
 * Check if checklist groups have been loaded and are present in the state
 */
export const areGroupsLoaded = createSelector(groups, (groups) => keys(groups).length > 0);

/**
 * Get an object with filter names as keys, containing arrays of period names
 */
export const getPeriodIds = createDeepEqualSelector(
  createSelector(periods, (periods) =>
    map<typeof periods, Record<IChecklistFilter, Array<string>>>(
      (periods) => sortPeriods(keys(periods)),
      periods,
    ),
  ),
  (periodIds) => periodIds,
);

/**
 * Returns an array of task IDs for a given period and filter
 */
const _getGroupTasks = createCachedSelector(
  (_: IApplicationState, period: string) => period,
  (_: IApplicationState, _period: string, filter: IChecklistFilter) => filter,
  periods,
  (period, filter, periods): Array<string> => periods[filter][period],
)((_: IApplicationState, period, filter) => `${period}:${filter}`);

// Deep equal compare before update
export const getGroupTasks = createCachedSelector(_getGroupTasks, (groupTasks) => groupTasks)(
  (_: IApplicationState, period, filter) => `${period}:${filter}`,
  {
    selectorCreator: createDeepEqualSelector,
  },
);

// Returns translated name of the task group, cached for performance
export const _getTaskGroupName = createCachedSelector(
  seedGroups,
  userLocale,
  (_: IApplicationState, taskId: string) => taskId,
  (seedGroups, _userLocale, taskId) => {
    const seedTask = seedGroups[taskId];
    if (seedTask) {
      const key = seedTask.i18nKey as string;
      return getGroupsTranslation[key]?.().name || seedTask.name || '';
    }
    return undefined;
  },
)((_, taskId) => taskId);

export const _getTaskGroup = createCachedSelector(
  groups,
  seedGroups,
  _getTaskGroupName,
  (_: IApplicationState, taskId: string) => taskId,
  (groups, seedGroups, taskGroupName, taskId) => {
    const seedTask = seedGroups[taskId];
    const taskGroup: IChecklistGroup = { ...groups[taskId] };
    if (seedTask && taskGroup) {
      taskGroup.name = taskGroupName || seedTask.name || '';
    }
    return taskGroup;
  },
)((_, taskId) => taskId);

// Deep equal compare before update
export const getTaskGroup = createCachedSelector(_getTaskGroup, (taskGroup) => taskGroup)(
  (_, taskId) => taskId,
  {
    selectorCreator: createDeepEqualSelector,
  },
);

export const getTaskName = createCachedSelector(
  tasksInitial,
  userLocale,
  (_: IApplicationState, task: ITask) => task,
  (tasksInitial, _userLocale, task) => {
    const getInitialTaskName = () => {
      const defaultName = tasksInitial[task.id].name;
      const key = tasksInitial[task.id].i18nKey;
      return getTasksTranslation()[key] || defaultName;
    };
    return tasksInitial[task.id] ? getInitialTaskName() : task.name;
  },
)((_, task) => task.id);

export const getTaskAssignee = createCachedSelector(
  tasks,
  (_: IApplicationState, taskId: string) => taskId,
  (tasks, taskId) => {
    // @ts-ignore FIXME
    const group = find(propEq('group', taskId))(values(tasks));
    // @ts-ignore FIXME
    return group ? group.assignee : undefined;
  },
)((_, taskId) => taskId);

export const getShowDetailsTask = createSelector(
  [seedGroups, detailsTaskId],
  (list, detailsTaskId) => (detailsTaskId ? list[detailsTaskId] : undefined),
);

/**
 * Count groups where all tasks done.
 */
export const getTasksDoneCount = createSelector([tasks], (tasks) => {
  const tasksCompleted = R.pipe(
    values(tasks) as Required<ITask>[],
    R.groupBy(R.prop('group')),
    R.toPairs,
    R.map((i) => (all(propEq('done', true), i[1]) ? true : null)),
  ).filter(Boolean).length;
  return tasksCompleted;
});

export const getTaskList = createSelector([tasks], (taskList) => {
  const tasksSorted = sortBy(prop('order'))(values(taskList));
  return groupBy(prop('group'), tasksSorted as Required<ITask>[]);
});

export const isGroupCompleted = createSelector(
  [(state: IApplicationState) => state.checklist.filter],
  (filter) => filter === 'completed',
);

/**
 * Get all tasks from specific group.
 * Return true if all tasks are done.
 */
export const getDoneGroup = createSelector([tasks, detailsTaskId], (taskList, groupId) => {
  const groupTasks = values(taskList).filter((task) => task.group === groupId);
  const done = all(propEq('done', true), groupTasks);
  return done;
});

export const getDrawerGroupPeriod = createSelector(
  [tasks, detailsTaskId],
  (taskList, groupId): string => {
    if (isEmpty(taskList)) return '';
    // @ts-expect-error
    const task: ITask | undefined = find<ITask>(propEq('group', groupId))(values(taskList));
    return task ? task.period : '';
  },
);

export const getEarliestPeriod = createSelector([tasks], (taskList) =>
  !taskList || isEmpty(taskList) ? '' : sortBy(prop('order'))(values(taskList))[0].period,
);

export const getNextTaskGroup = createSelector([tasks, seedGroups], (taskList, taskGroups) => {
  const nextTask = filter(
    (item) => !item.done && !item.custom,
    sortBy(prop('order'))(values(taskList)),
  )[0];

  const nextTaskGroupId = nextTask ? nextTask.group : undefined;
  return nextTaskGroupId ? taskGroups[nextTaskGroupId] : undefined;
});

export const getNumberOfNextTasks = createCachedSelector(
  tasks,
  seedGroups,
  (_: IApplicationState, numberOfTasks: number) => numberOfTasks,
  (taskList, taskGroups, numberOfTasks) => {
    const tasksList = values(taskList);
    const sortedTasks = sortBy(prop('order'))(tasksList);
    const undoneTasks = sortedTasks.filter((task) => !task.done);

    const nextTasks = undoneTasks
      .filter((task, index, self) => index === self.findIndex((t) => t.group === task.group))
      .slice(0, numberOfTasks);

    return nextTasks.map((item) => (item.custom || !item.group ? item : taskGroups[item.group]));
  },
)((_, numberOfTasks) => numberOfTasks);

export const getGroupSupplierSlug = createSelector([seedGroups, detailsTaskId], (groups, groupId) =>
  isEmpty(groups) || !groups || !groupId || !groups[groupId]
    ? undefined
    : groups[groupId].supplierType,
);

export const isDetailsDrawerOpened = createSelector(
  [groups, detailsTaskId],
  (groups, detailsTaskId) => Boolean(detailsTaskId && groups[detailsTaskId]),
);

export const getIsChecklistInitialised = createSelector(
  [(state) => state.checklist.initialised],
  (initialised) => initialised,
);
